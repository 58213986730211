import logo from "./logo.svg";
import "./App.css";
import Portfolio from "./components/Portfolio";
import { Helmet } from "react-helmet";

function App() {
  return (
    <div className="App">
      <Helmet>
        {/* Basic Meta Tags */}
        <title>Aryan Thakor | Portfolio</title>
        <meta
          name="description"
          content="Portfolio of Aryan Thakor, a Full Stack Developer showcasing work, skills, and projects."
        />

        {/* Open Graph Meta Tags */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://aryanthakor.com" />
        <meta property="og:title" content="Aryan Thakor | Portfolio" />
        <meta
          property="og:description"
          content="Portfolio of Aryan Thakor, a Full Stack Developer showcasing work, skills, and projects."
        />
        <meta property="og:image" content={`../public/favicon.png`} />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@aryanthakor" />
        <meta name="twitter:creator" content="@aryanthakor" />
        <meta name="twitter:title" content="Aryan Thakor | Portfolio" />
        <meta
          name="twitter:description"
          content="Portfolio of Aryan Thakor, a Full Stack Developer showcasing work, skills, and projects."
        />
        <meta name="twitter:image" content={`../public/favicon.png`} />

        {/* Additional Meta Tags */}
        <meta
          name="keywords"
          content="Aryan Thakor, Full Stack Developer, Portfolio, React, JavaScript, Web Development"
        />
        <meta name="author" content="Aryan Thakor" />
        <link rel="canonical" href="https://aryanthakor.com" />

        {/* Favicon and Apple Touch Icon */}
        <link rel="icon" href={`../public/favicon.png`} />
        <link rel="apple-touch-icon" href={`../public/apple-touch-icon.png`} />

        {/* Theme Color */}
        <meta name="theme-color" content="#ffffff" />

        {/* Structured Data for SEO */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Person",
              "name": "Aryan Thakor",
              "url": "https://aryanthakor.com",
              "sameAs": [
                "https://twitter.com/aryanthakor",
                "https://www.linkedin.com/in/aryanthakor",
                "https://github.com/aryanthakor"
              ],
              "jobTitle": "Full Stack Developer",
              "description": "Portfolio of Aryan Thakor, showcasing work, skills, and projects in Full Stack Development.",
              "image": "https://aryanthakor.com/favicon.png"
            }
          `}
        </script>
      </Helmet>
      <Portfolio />
    </div>
  );
}

export default App;
